import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Logo extends Component {
  render() {
    return (
      <div className={'logos-div'} style={{ position: 'absolute' }}>
        <img src={'img/image001.jpg'} alt='image001' width={70} />
        <img src={'img/image003.jpg'} alt='image003' width={70} style={{ marginLeft: '0.25em', marginRight: '0.25em' }} />
        <img src={'img/logo.png'} alt='logo' width={75} style={{ marginRight: '0.25em' }} />
        <div style={{ textAlign: 'center', fontSize: 'smaller', background: 'white', marginRight: '0.3em' }}>Created by <a href="http://www.cartografia.gr" target="new">www.cartografia.gr</a></div>
        <div style={{ textAlign: 'center', fontSize: 'small', background: 'white', marginRight: '0.3em' }}><Link to="/termsOfUse" target="_blank">Όροι Χρήσης και Πολιτική Απορρήτου</Link></div>
      </div>
    );
  }
}

export default Logo;