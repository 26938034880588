import React, { Component } from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import { isMobile } from 'react-device-detect';
import { map2d, map3d } from './config';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { fromLonLat } from 'ol/proj';
import { ScaleLine, defaults as defaultControls } from 'ol/control';
import ImageLayer from 'ol/layer/Image';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import { toLonLat } from 'ol/proj';
import MousePosition from 'ol/control/MousePosition';
import { createStringXY } from 'ol/coordinate';
import ZoomAll from '../../ol-custom/zoomAll/ZoomAll';
import LoadingProgress from '../../ol-custom/loadingProgress/LoadingProgress';
import { Circle as CircleStyle, Fill, Stroke, Style, Icon, Text } from 'ol/style';
import BarControl from 'ol-ext/control/Bar';
import ToggleControl from 'ol-ext/control/Toggle';
import ButtonControl from 'ol-ext/control/Button';
import { FlowerSpinner as Spinner } from 'react-epic-spinners'
import LayerSwitcherImage from '../../ol-custom/layerSwitcherImage/LayerSwitcherImage';
import LayerSwitcher from '../../ol-custom/layerSwitcher/LayerSwitcher';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addDrawInteraction } from '../../ol-custom/draw/Draw';
import { addMeasureInteraction } from '../../ol-custom/measure/Measure';
import { Print } from '../../ol-custom/print/Print';
// import Route from '../../ol-custom/route/Route';
import {
    Button,
    ButtonGroup,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledButtonDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    CardBody,
    Card, Row, Col, Alert
} from 'reactstrap';
import MobileLayout from '../common/mobileLayout';
import ChangeLanguage from "../common/changeLanguage";
import Feature from "ol/Feature";
import MaterialTable from "material-table";
import Logo from '../common/logo';
import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';
import '../../css/ol-map.css';

// import { jsPDF } from "jspdf";
// import html2canvas from 'html2canvas';

// import DejaVuSans from '../common/font';

const style = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.6)',
    }),
    stroke: new Stroke({
        color: '#319FD3',
        width: 1,
    }),
    text: new Text({
        font: 'bold 11px Arial',
        offsetY: -20,
        fill: new Fill({
            color: '#000',
        }),
        stroke: new Stroke({
            color: '#fff',
            width: 3,
        }),
    }),
});
class Greece extends Component {

    constructor(props) {
        super(props);
        this.state = {
            config: map3d,
            mapState: '3D',
            loading: false,
            ol_map: null,
            ol_map_basemaps_control: null,
            ol_map_mouse_position_control: null,
            info_results: [],
            places_to_visit: [],
            routeDistance: false,
            routeDuration: false,
            showInfoModal: false,
            showSearchModal: false,
            showIntroModal: true,
            showMoreMayorModal: false,
            showHistoryModal: false,
            showAboutTheProgramModal: false,
            measureTypeSelect: 'None',
            tableData: false,
            collapse: 0,
            cards: [1, 2, 3, 4],
            showAlert: false
        };

        this.doMapInfo = this.doMapInfo.bind(this);
        this.showMapInfo = this.showMapInfo.bind(this);
        this.toggleInfoModal = this.toggleInfoModal.bind(this);
        this.deleteFromPlaces = this.deleteFromPlaces.bind(this);
        this.doRoute = this.doRoute.bind(this);
        this.deleteRoute = this.deleteRoute.bind(this);
        this.addRouteToMap = this.addRouteToMap.bind(this);
        this.styleFunction = this.styleFunction.bind(this);
        this.notify = this.notify.bind(this);
        this.toggleSearchModal = this.toggleSearchModal.bind(this);
        this.toggleSearchPanel = this.toggleSearchPanel.bind(this);
        this.toggleIntroModal = this.toggleIntroModal.bind(this);
        this.toggleMoreMayorModal = this.toggleMoreMayorModal.bind(this);
        this.toggleHistoryModal = this.toggleHistoryModal.bind(this);
        this.toggleAboutTheProgramModal = this.toggleAboutTheProgramModal.bind(this);

        this.routeStops = 0;

        // Info
        this.mapInfoControl = null;

        // Draw
        this.draw = null;
        this.drawBar = null;
        this.drawSubButtons = null;
        this.drawSource = new VectorSource({ wrapX: false });
        this.drawVector = new VectorLayer({
            id: 'drawVector',
            baseLayer: false,
            source: this.drawSource,
        });

        // Measure
        this.measureBar = null;
        this.measureSubButtons = null;
        this.measureSource = new VectorSource();
        this.measureVector = new VectorLayer({
            id: 'measureVector',
            source: this.measureSource,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: '#ffcc33',
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: '#ffcc33',
                    }),
                }),
            }),
        });

        // Route layer
        this.routeVector = null;

        this.getTableData(this.state.config.layers);
    }

    getTableData(layers) {
        let groupedFeatures = {};
        const promises = [];
        layers.forEach(ly => {
            if (ly.get('info') && ly instanceof ImageLayer) {

                const url = /*process.env.REACT_APP_PROXY + */ly.get('source').getUrl().replace('wms', 'wfs') + '?SERVICE=WFS&version=2.0.0&request=GetFeature&typeNames=' + ly.get('source').getParams().LAYERS + '&outputFormat=application/json&srsName=EPSG:3857';

                const request = fetch(url).then(function (response) {
                    return response.json();
                });
                promises.push(request)
            } else if (ly.get('info') && ly instanceof VectorLayer) {
                const features = ly.get('source').getFeatures();

                features.forEach(f => {
                    f.setId(ly.get('id') + '.' + f.get('id'))
                });

                groupedFeatures[ly.get('id')] = JSON.parse(new GeoJSON().writeFeatures(features)).features;
            }
        });

        Promise.all(promises).then((responses) => {

            responses.forEach((response) => {
                if (response.features.length > 0) {
                    const category = response.features[0].id.split('.')[0];
                    groupedFeatures[category.replace('_transform', '')] = response.features;
                }
            });

            this.setState({
                tableData: groupedFeatures
            });

        });
    }

    groupBy(objectArray, property) {
        return objectArray.reduce((acc, obj) => {
            const key = obj.properties[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    componentDidMount() {

        let t = this;

        // Set Vector Layer Style 
        this.state.config.layers.forEach(ly => {
            if (ly.get('info') && ly instanceof VectorLayer) {
                ly.setStyle(
                    function (feature) {
                        style.getText().setText(feature.get('name_' + t.props.activeLanguage.code));
                        style.setImage(
                            new Icon({
                                anchor: [0.5, 0.5],
                                src: ly.get('legend'),
                                crossOrigin: '',
                                scale: [0.8, 0.8],
                            })
                        );
                        return style;
                    }
                )

            }
        });

        // Mouse coordinates position control
        const ol_map_mouse_position_control = new MousePosition({
            coordinateFormat: createStringXY(4),
            projection: 'EPSG:4326',
            className: 'custom-mouse-position',
            undefinedHTML: '&nbsp;'
        });

        // Basemap Layer Control
        const ol_map_basemaps_control = new LayerSwitcherImage();

        // Layers Control
        const ol_map_layers_control = new LayerSwitcher({
            extent: false
        });

        // Loading Progress Control
        const ol_map_LoadingProgressControl = new LoadingProgress();

        // Map Info Control
        this.mapInfoControl = new ToggleControl(
            {
                title: 'Info',
                html: '<i class="map-info-icon"></i>',
                onToggle: function (e) {
                    if (e) {
                        t.measureSource.clear();
                        let elements = document.getElementsByClassName('ol-overlay-container');
                        while (elements.length > 0) elements[0].remove();
                        t.state.ol_map.removeInteraction(t.draw);
                        if (t.drawBar.getActive()) {
                            t.drawBar.toggle()
                        }
                        t.drawBar.getSubBar().getControls().forEach(function (ctrl) {
                            try {
                                if (ctrl.getActive()) {
                                    ctrl.toggle()
                                }
                            } catch (e) {
                            }
                        });
                        if (t.measureBar.getActive()) {
                            t.measureBar.toggle()
                        }
                        t.measureBar.getSubBar().getControls().forEach(function (ctrl) {
                            try {
                                if (ctrl.getActive()) {
                                    ctrl.toggle()
                                }
                            } catch (e) {
                            }
                        });
                        ol_map.on('singleclick', t.doMapInfo);
                        ol_map.getViewport().style.cursor = 'help';
                    } else {
                        ol_map.un('singleclick', t.doMapInfo);
                        ol_map.getViewport().style.cursor = 'default';
                    }
                }
            });

        // Draw Main Bar Control
        this.drawSubButtons = new BarControl(
            {
                toggleOne: true,
                controls:
                    [new ToggleControl(
                        {
                            title: 'Draw Point',
                            html: '<i class="draw-point"></i>',
                            onToggle: function (b) {
                                if (b) {
                                    t.state.ol_map.removeInteraction(t.draw);
                                    t.draw = addDrawInteraction('Point', t.drawSource, t.state.ol_map);
                                } else {
                                    t.state.ol_map.removeInteraction(t.draw);
                                }
                            }
                        }),
                    new ToggleControl(
                        {
                            title: 'Draw Line',
                            html: '<i class="draw-line"></i>',
                            onToggle: function (b) {
                                if (b) {
                                    t.state.ol_map.removeInteraction(t.draw);
                                    t.draw = addDrawInteraction('LineString', t.drawSource, t.state.ol_map);
                                } else {
                                    t.state.ol_map.removeInteraction(t.draw);
                                }
                            }
                        }),
                    new ToggleControl(
                        {
                            title: 'Draw Polygon',
                            html: '<i class="draw-polygon"></i>',
                            onToggle: function (b) {
                                if (b) {
                                    t.state.ol_map.removeInteraction(t.draw);
                                    t.draw = addDrawInteraction('Polygon', t.drawSource, t.state.ol_map);
                                } else {
                                    t.state.ol_map.removeInteraction(t.draw);
                                }
                            }
                        }),
                    new ButtonControl(
                        {
                            title: 'Delete Drawings',
                            html: '<i class="draw-delete"></i>',
                            handleClick: function () {
                                t.drawSource.clear()
                            }
                        })
                    ]
            });
        this.drawBar = new ToggleControl(
            {
                title: 'Draw',
                html: '<i class="draw-draw"></i>',
                bar: t.drawSubButtons,
                onToggle: function (e) {
                    if (e) {
                        t.measureSource.clear();
                        let elements = document.getElementsByClassName('ol-overlay-container');
                        while (elements.length > 0) elements[0].remove();
                        if (t.mapInfoControl.getActive()) {
                            t.mapInfoControl.toggle()
                        }
                        ol_map.un('singleclick', t.doMapInfo);
                        if (t.measureBar.getActive()) {
                            t.measureBar.toggle()
                        }
                        if (t.measureBar.getActive()) {
                            t.measureBar.toggle()
                        }
                        t.measureBar.getSubBar().getControls().forEach(function (ctrl) {
                            t.state.ol_map.removeInteraction(t.draw);
                            try {
                                if (ctrl.getActive()) {
                                    ctrl.toggle()
                                }
                            } catch (e) {
                            }
                        });
                        ol_map.getViewport().style.cursor = 'default';
                    } else {
                        t.drawBar.getSubBar().getControls().forEach(function (ctrl) {
                            t.state.ol_map.removeInteraction(t.draw);
                            try {
                                if (ctrl.getActive()) {
                                    ctrl.toggle()
                                }
                            } catch (e) {
                            }
                        });
                    }
                }
            });

        // Measure Bar Control
        this.measureSubButtons = new BarControl(
            {
                title: 'Measure',
                toggleOne: true,
                controls:
                    [new ToggleControl(
                        {
                            title: 'Measure Distance',
                            html: '<i class="measure-icon-line"></i>',
                            onToggle: function (b) {
                                t.state.ol_map.removeInteraction(t.draw);
                                t.draw = addMeasureInteraction('LineString', t.measureSource, t.state.ol_map);
                            }
                        }),
                    new ToggleControl(
                        {
                            title: 'Measure Area',
                            html: '<i class="measure-icon-polygon"></i>',
                            onToggle: function (b) {
                                t.state.ol_map.removeInteraction(t.draw);
                                t.draw = addMeasureInteraction('Polygon', t.measureSource, t.state.ol_map);
                            }
                        })
                    ]
            });
        this.measureBar = new ToggleControl({
            title: 'Measure',
            html: '<i class="measure-icon"></i>',
            bar: t.measureSubButtons,
            onToggle: function (e) {
                if (e) {
                    t.state.ol_map.removeInteraction(t.draw);
                    if (t.mapInfoControl.getActive()) {
                        t.mapInfoControl.toggle()
                    }
                    t.state.ol_map.un('singleclick', t.doMapInfo);
                    if (t.drawBar.getActive()) {
                        t.drawBar.toggle()
                    }
                    t.drawBar.getSubBar().getControls().forEach(function (ctrl) {
                        try {
                            if (ctrl.getActive()) {
                                ctrl.toggle()
                            }
                        } catch (e) {
                        }
                    });
                    ol_map.getViewport().style.cursor = 'default';
                } else {
                    t.measureBar.getSubBar().getControls().forEach(function (ctrl) {
                        t.state.ol_map.removeInteraction(t.draw);
                        try {
                            if (ctrl.getActive()) {
                                ctrl.toggle()
                            }
                        } catch (e) {
                        }
                    });
                    t.measureSource.clear();
                    let elements = document.getElementsByClassName('ol-overlay-container');
                    while (elements.length > 0) elements[0].remove();
                }
            }
        });

        // About left toolbar
        const leftAboutControl = new ToggleControl(
            {
                title: 'Πληροφορίες',
                html: '<i class="about"></i>',
                bar: new BarControl(
                    {
                        toggleOne: true,
                        controls:
                            [
                                new ButtonControl({
                                    title: 'Εισαγωγή',
                                    html: 'i',
                                    handleClick: function () {
                                        t.setState({
                                            showIntroModal: !t.state.showIntroModal
                                        })
                                        leftAboutControl.toggle()
                                    }
                                }),
                                new ButtonControl({
                                    title: 'Χαιρετισμός Δημάρχου',
                                    html: 'Δ',
                                    handleClick: function () {
                                        t.setState({
                                            showMoreMayorModal: !t.state.showMoreMayorModal
                                        })
                                        leftAboutControl.toggle()
                                    }
                                }),
                                new ButtonControl({
                                    title: 'Ιστορική Αναδρομή',
                                    html: 'H',
                                    handleClick: function () {
                                        t.setState({
                                            showHistoryModal: !t.state.showHistoryModal
                                        })
                                        leftAboutControl.toggle()
                                    }
                                }),
                                new ButtonControl({
                                    title: 'Σχετικά με το Πρόγραμμα',
                                    html: 'Π',
                                    handleClick: function () {
                                        t.setState({
                                            showAboutTheProgramModal: !t.state.showAboutTheProgramModal
                                        })
                                        leftAboutControl.toggle()
                                    }
                                }),

                            ]
                    }),
                onToggle: function (e) {
                    if (e) {

                    } else {

                    }
                }
            });

        // Main Left Toolbar
        const leftBarControl = new BarControl({
            controls: [
                new ButtonControl({
                    title: '2D/3D',
                    html: '<i class="map-icon"></i>',
                    handleClick: function () {
                        t.state.mapState === '2D' ? t.setMap3D() : t.setMap2D();
                    }
                }),
                t.mapInfoControl,
                new ButtonControl({
                    title: 'Search',
                    html: '<i class="search-poi"></i>',
                    handleClick: function () {
                        t.setState({
                            showSearchModal: !t.state.showSearchModal
                        })
                    }
                }),
                t.drawBar,
                t.measureBar,
                // new ButtonControl({
                //     title: 'Print',
                //     html: '<i class="print-icon"></i>',
                //     handleClick: function () {
                //         t.print();
                //     }
                // }),
                new ZoomAll({
                    xy: t.state.config.xy,
                    zoom: t.state.config.zoom,
                    extra: -1
                }),
                // leftAboutControl
            ]
        });

        let ol_map = new Map({
            controls: defaultControls().extend([
                ol_map_basemaps_control,
                ol_map_layers_control,
                new ScaleLine({
                    units: "metric",
                    bar: true,
                    steps: 4,
                    text: false,
                    minWidth: 140,
                }),
                // new OverviewMap({
                //     className: 'ol-overviewmap ol-custom-overviewmap',
                //     layers: [
                //         new TileLayer({
                //             source: new XYZ({
                //                 url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
                //             }),
                //         })],
                //     collapseLabel: '\u00BB',
                //     label: '\u00AB'
                // }),
                // ol_map_mouse_position_control,
                ol_map_LoadingProgressControl
            ]),
            layers: t.state.config.layers,
            target: 'map_div',
            view: new View({
                center: t.state.config.xy,
                zoom: t.state.config.zoom,
                extent: t.state.config.extent_limits
            })
        });

        ol_map_LoadingProgressControl.set();

        leftBarControl.setPosition('top-left');
        ol_map.addControl(leftBarControl);

        ol_map.addLayer(t.drawVector);
        ol_map.addLayer(t.measureVector);

        // Reset route stops
        ol_map.on('moveend', function (evt) {
            t.routeStops = 0;
        });

        t.setState({
            ol_map: ol_map,
            ol_map_basemaps_control: ol_map_basemaps_control,
            ol_map_layers_control: ol_map_layers_control,
            ol_map_mouse_position_control: ol_map_mouse_position_control
        });

        setTimeout(function () {
            ol_map.updateSize();
            ol_map.getView().setZoom(ol_map.getView().getZoom() - 1);
        }, 1000);

        this.setLayerTranslate();

        this.state.mapState === '3D' ? document.getElementsByClassName("ol-layerswitcher-image")[0].style.display = "none" : document.getElementsByClassName("ol-layerswitcher-image")[0].style.display = "block";
        this.state.mapState === '3D' ? document.getElementsByClassName("ol-scale-bar")[0].style.display = "none" : document.getElementsByClassName("ol-scale-bar")[0].style.display = "block";
        this.state.mapState === '3D' ? this.measureBar.setDisable(true) : this.measureBar.setDisable(false)
    }

    // INFO

    info() {
        this.measureSource.clear();
        let elements = document.getElementsByClassName('ol-overlay-container');
        while (elements.length > 0) elements[0].remove();
        this.state.ol_map.removeInteraction(this.draw);
        if (this.drawBar.getActive()) {
            this.drawBar.toggle()
        }
        this.drawBar.getSubBar().getControls().forEach(function (ctrl) {
            try {
                if (ctrl.getActive()) {
                    ctrl.toggle()
                }
            } catch (e) {
            }
        });
        if (this.measureBar.getActive()) {
            this.measureBar.toggle()
        }
        this.measureBar.getSubBar().getControls().forEach(function (ctrl) {
            try {
                if (ctrl.getActive()) {
                    ctrl.toggle()
                }
            } catch (e) {
            }
        });
        this.mapInfoControl.setActive(true);
        this.state.ol_map.on('singleclick', this.doMapInfo);
        this.state.ol_map.getViewport().style.cursor = 'help';
    }

    doMapInfo(evt) {

        const t = this;
        let requests = [];
        const layers = evt.map.getLayers();
        const viewResolution = /** @type {number} */ (evt.map.getView().getResolution());

        t.setState({
            loading: true,
            info_results: []
        });

        let feature = null;
        layers.forEach(function (item) {
            if (!item.get('baseLayer') && item.get('info') && item.get('visible') && item.get('id') !== 'drawVector' && item.get('id') !== 'measureVector' && item.get('id') !== 'routeVector' && item instanceof ImageLayer) {
                const url = item.get('source').getFeatureInfoUrl(
                    evt.coordinate,
                    viewResolution,
                    'EPSG:3857',
                    { 'INFO_FORMAT': 'application/json' }
                );
                if (url) {
                    const request = fetch(url).then(function (response) {
                        // const request = fetch(process.env.REACT_APP_PROXY + url.replace('?', '&')).then(function (response) {
                        return response.json();
                    });
                    requests.push(request);
                }
            } else if (!item.get('baseLayer') && item.get('info') && item.get('visible') && item.get('id') !== 'drawVector' && item.get('id') !== 'measureVector' && item.get('id') !== 'routeVector' && item instanceof VectorLayer) {
                feature = evt.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                    return feature;
                });
                if (feature) {
                    feature.setId(item.get('id') + '.' + feature.get('id'))
                }
            }
        });

        if (feature) {
            t.setState({
                loading: false,
                info_results: [JSON.parse(new GeoJSON().writeFeatures([feature]))]
            }, t.showMapInfo);
        } else {
            Promise.all(requests).then((responses) => {
                t.setState({ loading: false });
                let r = [];
                responses.forEach((response) => {
                    if (response.features.length > 0) {
                        r.push(response)
                    }
                });
                t.setState({
                    info_results: r
                }, t.showMapInfo);
            });
        }



    }

    showMapInfo() {
        if (this.state.info_results.length > 0) {
            this.toggleInfoModal();
        }

    }

    toggleInfoModal() {
        this.setState({
            showInfoModal: !this.state.showInfoModal
        })
    }

    addToPlaces(e, toggle) {

        this.deleteRoute();
        let isAddedToList = false;
        this.state.places_to_visit.forEach((place) => {
            if (place.id === e.id) {
                isAddedToList = true
            }
        });
        if (!isAddedToList) {
            this.setState({
                places_to_visit: [...this.state.places_to_visit, e]
            });
            if (toggle) {
                this.toggleInfoModal()
            }
        }
    }

    deleteFromPlaces(e) {
        const id = e.target.id;
        let newListPlacesToVisit = this.state.places_to_visit.filter(item => item.id !== id);
        this.deleteRoute();
        this.setState({
            places_to_visit: newListPlacesToVisit
        });

    }

    doRoute() {
        let coordinates = this.state.places_to_visit.map((place) =>
            place.geometry.type === 'MultiPoint' ? toLonLat(place.geometry.coordinates[0], 'EPSG:3857') : toLonLat(place.geometry.coordinates, 'EPSG:3857')
        );

        const data = { coordinates: coordinates };

        this.setState({
            loading: true,
            routeDuration: false,
            routeDistance: false
        });

        const t = this;
        (async () => {
            const rawResponse = await fetch(process.env.REACT_APP_PROXY + 'https://api.openrouteservice.org/v2/directions/driving-car/geojson&SERVICE=ROUTE', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_ROUTE_TOKEN
                },
                body: JSON.stringify(data)
            });
            const content = await rawResponse.json();
            if (!content.error) {
                t.addRouteToMap(content);

                t.setState({
                    loading: false,
                    routeDistance: (content.features[0].properties.summary.distance / 1000).toFixed(1),
                    routeDuration: (content.features[0].properties.summary.duration / 60).toFixed(0)
                });

                try {
                    const coordMin = fromLonLat([content.bbox[0], content.bbox[1]], 'EPSG:3857');
                    const coordMax = fromLonLat([content.bbox[2], content.bbox[3]], 'EPSG:3857');
                    const extent = [coordMin[0], coordMin[1], coordMax[0], coordMax[1]];
                    t.state.ol_map.getView().fit(extent, t.state.ol_map.getSize());
                    t.state.ol_map.getView().setZoom(t.state.ol_map.getView().getZoom() - 2);
                } catch (e) {
                    t.setState({ loading: false });
                    t.notify(t.props.translate('errorRoute'), 'error');
                }
            } else {
                t.setState({ loading: false });
                t.notify(t.props.translate('errorRoute'), 'error');
            }
        })();
    }

    addRouteToMap(geojsonObject) {

        const t = this;
        this.state.ol_map.removeLayer(this.routeVector);
        this.routeStops = 0;

        const features = new GeoJSON().readFeatures(geojsonObject);
        features.forEach(function (feature) {
            feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
        });
        this.routeVector = new VectorLayer({
            id: 'routeVector',
            source: new VectorSource({
                features: features
            }),
            style: t.styleFunction
        });

        this.state.places_to_visit.forEach((f, index) => {
            t.routeVector.getSource().addFeature(
                new Feature({
                    geometry: new Point(f.geometry.type === 'Point' ? f.geometry.coordinates : f.geometry.coordinates[0]),
                    i: index,
                    size: index % 2 ? 10 : 20,
                    _gx_style: index,
                    title: f.properties['name_' + t.props.activeLanguage.code]
                })
            )
        });

        this.state.ol_map.addLayer(this.routeVector);
    }

    deleteRoute() {
        this.setState({
            routeDistance: false,
            routeDuration: false
        });
        this.state.ol_map.removeLayer(this.routeVector);
        this.routeStops = 0;
    }

    styleFunction(feature) {
        const styles = {
            // linestring
            'LineString': new Style({
                stroke: new Stroke({
                    color: '#fcf75e',
                    width: 6,
                })
            }),
            'Point-1': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_1.png',
                }),
            }),
            'Point-2': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_2.png',
                }),
            }),
            'Point-3': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_3.png',
                }),
            }),
            'Point-4': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_4.png',
                }),
            }),
            'Point-5': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_5.png',
                }),
            }),
            'Point-6': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_6.png',
                }),
            }),
            'Point-7': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_7.png',
                }),
            }),
            'Point-8': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_8.png',
                }),
            }),
            'Point-9': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_9.png',
                }),
            }),
            'Point-10': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_10.png',
                }),
            }),
            'Point-11': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_11.png',
                }),
            }),
            'Point-12': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_12.png',
                }),
            }),
            'Point-13': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_13.png',
                }),
            }),
            'Point-14': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_14.png',
                }),
            }),
            'Point-15': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/markers/point_15.png',
                }),
            })
        };
        if (feature.getGeometry().getType() === 'Point') {
            const pointNumber = feature.get('_gx_style') + 1;
            return styles['Point-' + pointNumber];
        } else {
            return styles[feature.getGeometry().getType()];
        }
    };

    notify(message, type) {
        if (type === 'error') {
            toast.error(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    toggleSearchModal() {
        this.setState({
            showSearchModal: !this.state.showSearchModal
        })
    }

    toggleSearchPanel(e) {
        let event = e.target.dataset.event;
        this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) });
    }

    toggleIntroModal() {
        this.setState({
            showIntroModal: !this.state.showIntroModal
        })
    }

    toggleMoreMayorModal() {
        this.setState({
            showMoreMayorModal: !this.state.showMoreMayorModal
        })
    }

    toggleHistoryModal() {
        this.setState({
            showHistoryModal: !this.state.showHistoryModal
        })
    }

    toggleAboutTheProgramModal() {
        this.setState({
            showAboutTheProgramModal: !this.state.showAboutTheProgramModal
        })
    }

    print() {
        const doPrint = Print(this.state.ol_map, process.env.REACT_APP_PRINT, this.state.routeDistance, this.state.routeDuration, this.props.translate);
        doPrint.then(res => res.json()).then((json) => {
            this.setState({ loading: false }, (e) => {
                const newWin = window.open(json.getURL.replace('http://localhost:8080/geoserver', process.env.REACT_APP_PRINT_DOWNLOAD));
                if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                    this.setState({ showAlert: true })
                }
            });
            setTimeout(() => { this.setState({ showAlert: false }) }, 4000);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let t = this;
        if (prevProps.activeLanguage !== this.props.activeLanguage) {

            this.setLayerTranslate();

            // Set Vector Layer Style 
            this.state.config.layers.forEach(ly => {
                if (ly.get('info') && ly instanceof VectorLayer) {
                    ly.setStyle(
                        function (feature) {
                            style.getText().setText(feature.get('name_' + t.props.activeLanguage.code));
                            style.setImage(
                                new Icon({
                                    anchor: [0.5, 0.5],
                                    src: ly.get('legend'),
                                    crossOrigin: '',
                                    scale: [0.8, 0.8],
                                })
                            );
                            return style;
                        }
                    )
                }
                // if (ly.get('info') && ly instanceof ImageLayer) {
                //     console.log(ly.getSource());

                // }
            });
        }

    }

    setMap3D() {
        this.setState({
            config: map3d,
            mapState: '3D',
            tableData: false
        }, () => {
            this.getTableData(map3d.layers);
            map2d.layers.forEach((e) => {
                this.state.ol_map.removeLayer(e);
            })
            map3d.layers.forEach((e) => {
                this.state.ol_map.addLayer(e);
            })

            this.state.ol_map.setView(new View({
                center: map3d.xy,
                extent: map3d.extent_limits,
                zoom: map3d.zoom
            }));

            this.state.ol_map.getLayers().getArray().push(this.state.ol_map.getLayers().getArray().shift());
            this.state.ol_map.getLayers().getArray().push(this.state.ol_map.getLayers().getArray().shift());

            this.state.ol_map.removeControl(this.state.ol_map_mouse_position_control);
            document.getElementsByClassName("ol-layerswitcher-image")[0].style.display = "none";
            document.getElementsByClassName("ol-scale-bar")[0].style.display = "none";
            this.measureBar.setDisable(true);

            this.setLayerTranslate();

        })
    }

    setMap2D() {
        this.setState({
            config: map2d,
            mapState: '2D',
            tableData: false
        }, () => {
            this.getTableData(map2d.layers);
            map3d.layers.forEach((e) => {
                this.state.ol_map.removeLayer(e);
            })
            map2d.layers.forEach((e) => {
                this.state.ol_map.addLayer(e);
            })

            this.state.ol_map.setView(new View({
                center: map2d.xy,
                extent: map2d.extent_limits,
                zoom: map2d.zoom
            }));

            this.state.ol_map.getLayers().getArray().push(this.state.ol_map.getLayers().getArray().shift());
            this.state.ol_map.getLayers().getArray().push(this.state.ol_map.getLayers().getArray().shift());

            this.state.ol_map.addControl(this.state.ol_map_mouse_position_control);
            document.getElementsByClassName("ol-layerswitcher-image")[0].style.display = "block";
            window.innerWidth > 500 ? document.getElementsByClassName("ol-layerswitcher-image")[0].style.bottom = '2.5em' : document.getElementsByClassName("ol-layerswitcher-image")[0].style.bottom = 0;
            window.innerWidth < 380 ? document.getElementsByClassName("ol-layerswitcher-image")[0].style.display = 'none' : document.getElementsByClassName("ol-layerswitcher-image")[0].style.display = "block";
            window.innerWidth > 500 ? document.getElementsByClassName("ol-scale-bar")[0].style.display = "block" : document.getElementsByClassName("ol-scale-bar")[0].style.display = "none";
            this.measureBar.setDisable(false);

            console.log(window.innerWidth)

            this.setLayerTranslate();

        })
    }

    setLayerTranslate() {
        const t = this;
        t.state.config.layers.forEach(function (item) {
            item.values_.title = t.props.translate(item.get('id'));
        });
        try {
            t.state.ol_map.removeControl(t.state.ol_map_layers_control);
            t.state.ol_map_basemaps_control._layers.forEach(function (item) {
                item.layer.values_.title = t.props.translate(item.layer.values_.id);
            });
            t.state.ol_map_layers_control._layers.forEach(function (item) {
                item.layer.values_.title = t.props.translate(item.layer.values_.id);
            });
            t.state.ol_map_layers_control._layers.forEach(function (item) {

                if (t.props.activeLanguage.code !== 'gr') {
                    item.layer.getSource().updateParams({
                        STYLES: item.layer.values_.id + '_' + t.props.activeLanguage.code
                    })
                } else {
                    item.layer.getSource().updateParams({
                        STYLES: ''
                    })
                }
                item.layer.values_.title = t.props.translate(item.layer.values_.id);
            });
            t.state.ol_map.addControl(t.state.ol_map_layers_control);
        } catch (e) {
        }
    }

    render() {
        // if (!isMobile) {
        return (<div className="map_parent_div">

            <ChangeLanguage />

            <div id="map_div" className="map" />

            <ButtonGroup className="menu_div">
                {
                    this.state.mapState === '3D' ? <Button size="sm" onClick={(e) => { this.setMap2D() }}> 2D </Button> : <Button size="sm" onClick={(e) => { this.setMap3D() }}> 3D </Button>
                }
                <Button size="sm" onClick={(e) => { this.info(); }}> <Translate id='info' /> </Button>
                <Button size="sm" onClick={(e) => this.toggleSearchModal()}>
                    <Translate id='search' />
                </Button>
                <UncontrolledButtonDropdown>
                    <DropdownToggle caret style={{ fontSize: '0.875rem' }}>
                        <Translate id='draw' />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => {
                            this.state.ol_map.un('singleclick', this.doMapInfo);
                            this.state.ol_map.getViewport().style.cursor = 'default';
                            this.mapInfoControl.setActive(false);
                            this.measureBar.setActive(false);
                            this.drawBar.setActive(true);
                            this.drawSubButtons.getControls()[0].setActive(true);
                            this.state.ol_map.removeInteraction(this.draw);
                            this.draw = addDrawInteraction('Point', this.drawSource, this.state.ol_map);
                        }}>
                            <Translate id='drawPoint' />
                        </DropdownItem>
                        <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => {
                            this.state.ol_map.un('singleclick', this.doMapInfo);
                            this.state.ol_map.getViewport().style.cursor = 'default';
                            this.mapInfoControl.setActive(false);
                            this.measureBar.setActive(false);
                            this.drawBar.setActive(true);
                            this.drawSubButtons.getControls()[1].setActive(true);
                            this.state.ol_map.removeInteraction(this.draw);
                            this.draw = addDrawInteraction('LineString', this.drawSource, this.state.ol_map);
                        }}>
                            <Translate id='drawLine' />
                        </DropdownItem>
                        <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => {
                            this.state.ol_map.un('singleclick', this.doMapInfo);
                            this.state.ol_map.getViewport().style.cursor = 'default';
                            this.mapInfoControl.setActive(false);
                            this.measureBar.setActive(false);
                            this.drawBar.setActive(true);
                            this.drawSubButtons.getControls()[2].setActive(true);
                            this.state.ol_map.removeInteraction(this.draw);
                            this.draw = addDrawInteraction('Polygon', this.drawSource, this.state.ol_map);
                        }}>
                            <Translate id='drawPolygon' />
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => this.drawSource.clear()}>
                            <Translate id='drawDelete' />
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                {
                    this.state.mapState === '2D' ?
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret style={{ fontSize: '0.875rem' }}>
                                <Translate id='measure' />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => {
                                    this.state.ol_map.un('singleclick', this.doMapInfo);
                                    this.state.ol_map.getViewport().style.cursor = 'default';
                                    this.mapInfoControl.setActive(false);
                                    this.drawBar.setActive(false);
                                    this.measureBar.setActive(true);
                                    this.measureSubButtons.getControls()[0].setActive(true);
                                    this.state.ol_map.removeInteraction(this.draw);
                                    this.draw = addMeasureInteraction('LineString', this.measureSource, this.state.ol_map);
                                }}>
                                    <Translate id='measureDistance' />
                                </DropdownItem>
                                <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => {
                                    this.state.ol_map.un('singleclick', this.doMapInfo);
                                    this.state.ol_map.getViewport().style.cursor = 'default';
                                    this.mapInfoControl.setActive(false);
                                    this.drawBar.setActive(false);
                                    this.measureBar.setActive(true);
                                    this.measureSubButtons.getControls()[1].setActive(true);
                                    this.state.ol_map.removeInteraction(this.draw);
                                    this.draw = addMeasureInteraction('Polygon', this.measureSource, this.state.ol_map);
                                }}>
                                    <Translate id='measureArea' />
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => {
                                    const t = this;
                                    t.measureBar.getSubBar().getControls().forEach(function (ctrl) {
                                        t.state.ol_map.removeInteraction(t.draw);
                                        try {
                                            if (ctrl.getActive()) {
                                                ctrl.toggle()
                                            }
                                        } catch (e) {
                                        }
                                    });
                                    t.measureSource.clear();
                                    let elements = document.getElementsByClassName('ol-overlay-container');
                                    while (elements.length > 0) elements[0].remove();
                                    t.measureBar.setActive(false);
                                }}>
                                    <Translate id='measureDelete' />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        : null
                }
                {
                    // <Button size="sm" onClick={(e) => this.print()}>
                    //     <Translate id='print' />
                    // </Button>
                }
                <Button size="sm" onClick={(e) => this.state.ol_map.getView().animate({ center: this.state.config.xy, zoom: this.state.config.zoom - 1 })}>
                    <Translate id='zoomAll' />
                </Button>
                <UncontrolledButtonDropdown>
                    <DropdownToggle caret style={{ fontSize: '0.875rem' }}>
                        <Translate id='usefulInformation' />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => this.toggleIntroModal()}>
                            <Translate id='intro' />
                        </DropdownItem>
                        <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => this.toggleMoreMayorModal()}>
                            <Translate id='mayor' />
                        </DropdownItem>
                        <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => this.toggleHistoryModal()}>
                            <Translate id='history' />
                        </DropdownItem>
                        <DropdownItem style={{ fontSize: '0.875rem' }} onClick={(e) => this.toggleAboutTheProgramModal()}>
                            <Translate id='aboutTheProgram' />
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </ButtonGroup>

            <Logo />

            {
                this.state.showAlert ?
                    <Alert color="danger" style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '20em',
                        top: '0.5em'
                    }}>
                        <Translate id='popupsAllow' />
                    </Alert>
                    : null
            }

            {
                // this.state.places_to_visit.length > 0 ?
                //     <Route features={this.state.places_to_visit} funcDelete={this.deleteFromPlaces}
                //         funcRoute={this.doRoute} funcDeleteRoute={this.deleteRoute}
                //         routeDistance={this.state.routeDistance}
                //         routeDuration={this.state.routeDuration}
                //         lang={this.props.activeLanguage.code} />
                //     : ''
            }

            <Modal isOpen={this.state.showInfoModal} toggle={this.toggleInfoModal} scrollable={true}>
                <ModalHeader
                    toggle={this.toggleInfoModal}>{this.state.info_results.length > 0 ? this.state.info_results[0].features[0].properties['name_' + this.props.activeLanguage.code] : ''}</ModalHeader>
                <ModalBody>
                    {
                        this.state.info_results.length > 0 && this.state.info_results[0].features[0].properties['description_' + this.props.activeLanguage.code] ?
                            this.state.info_results[0].features[0].properties['description_' + this.props.activeLanguage.code].split('</p><p>').map((paragraph, index) =>
                                <p key={`p_${index}`}>{paragraph.replace('<p>', '').replace('</p>', '')}</p>
                            )
                            : ''
                    }
                    {
                        this.state.info_results.length > 0 && this.state.info_results[0].features[0].properties['video'] ?
                            this.state.info_results[0].features[0].properties['video'].split(',').map((video, index) =>
                                <iframe title='video-iframe' key={`iframe_${index}`} src={'photos/' + video + '.mp4'}
                                    style={{ width: '100%', height: '30rem' }} />
                            )
                            : ''
                    }
                    {
                        this.state.info_results.length > 0 && this.state.info_results[0].features[0].properties['photo'] ?
                            this.state.info_results[0].features[0].properties['photo'].split(',').map((photo, index) =>
                                <div key={`div_img_${index}`}><img key={`img_${index}`} src={'photos/' + photo + '.jpg'} alt="info-1" style={{ width: '100%' }} /><br /><br /></div>
                            )
                            : ''
                    }
                </ModalBody>
                <ModalFooter>
                    {
                        // (this.state.info_results.length > 0 && this.state.places_to_visit.some(place => place.id === this.state.info_results[0].features[0]['id'])) || (this.state.places_to_visit.length > 14) ?
                        //     <Button disabled={true} color="success"
                        //         onClick={() => this.addToPlaces(this.state.info_results[0].features[0], true)}><Translate
                        //             id="visit" /></Button>
                        //     :
                        //     <Button disabled={false} color="success"
                        //         onClick={() => this.addToPlaces(this.state.info_results[0].features[0], true)}><Translate
                        //             id="visit" /></Button>
                    }
                </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.showSearchModal} toggle={this.toggleSearchModal} scrollable={true}>
                <ModalHeader toggle={this.toggleSearchModal}>{this.props.translate('search')}</ModalHeader>
                <ModalBody>{
                    this.state.config.layers.slice(0).reverse().map((e, index) => {
                        return !e.get('baseLayer') && e.get('info') ?
                            <div key={index}>
                                <Button id={"toggler_" + index}
                                    style={{
                                        width: '100%',
                                        marginTop: '0.5em',
                                        marginBottom: '0.5em'
                                    }}
                                    data-event={index}
                                    onClick={this.toggleSearchPanel}
                                >{e.get('title')}</Button>
                                <Collapse isOpen={this.state.collapse === index}>
                                    <Card>
                                        <CardBody>
                                            {
                                                this.state.tableData ?
                                                    <MaterialTable
                                                        title=" "
                                                        columns={[
                                                            {
                                                                title: this.props.translate('name'),
                                                                field: 'name_' + this.props.activeLanguage.code
                                                            }
                                                        ]}
                                                        data={
                                                            this.state.tableData[e.get('id')].map((item) => {
                                                                item.properties.geometry = item.geometry;
                                                                item.properties.feature = item;
                                                                return item.properties
                                                            })
                                                        }
                                                        detailPanel={rowData => {
                                                            const img = rowData.photo ? rowData.photo.split(',')[0] : null;
                                                            return (
                                                                <div style={{ display: 'flex' }}>
                                                                    {
                                                                        img ? <img
                                                                            style={{ margin: '1em' }}
                                                                            width="50%"
                                                                            height="50%"
                                                                            alt="info-2"
                                                                            src={'photos/' + img + '.jpg'}
                                                                        /> : null
                                                                    }

                                                                    <div style={{ margin: '1em' }}>
                                                                        {
                                                                            rowData['description_' + this.props.activeLanguage.code] ? rowData['description_' + this.props.activeLanguage.code].split('</p><p>').map((paragraph, index) =>
                                                                                <p key={`p_${index}`}>{paragraph.replace('<p>', '').replace('</p>', '')}</p>
                                                                            ) : <p>{this.props.translate('noDescription')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        }
                                                        options={{
                                                            search: true,
                                                            padding: 0,
                                                            searchFieldStyle: {
                                                                fontSize: 12,
                                                            },
                                                            rowStyle: {
                                                                fontSize: 12
                                                            }
                                                        }}
                                                        actions={[
                                                            {
                                                                icon: 'search',
                                                                tooltip: this.props.translate('zoomTo'),
                                                                onClick: (event, rowData) => {
                                                                    try {
                                                                        // const point = fromLonLat(rowData.geometry.coordinates[0], 'EPSG:3857');
                                                                        this.state.ol_map.getView().setCenter(rowData.geometry.coordinates);
                                                                        this.state.ol_map.getView().setZoom(17);
                                                                        this.toggleSearchModal();
                                                                    } catch (e) {

                                                                    }
                                                                }
                                                            },
                                                            // {
                                                            //     icon: 'favorite_border',
                                                            //     tooltip: this.props.translate('visit'),
                                                            //     onClick: (event, rowData) => {
                                                            //         event.preventDefault()
                                                            //         try {
                                                            //             if (rowData.feature.geometry === null) {
                                                            //                 this.setState({ loading: false });
                                                            //                 this.notify(this.props.translate('errorGeometry'), 'error');
                                                            //             } else {
                                                            //                 this.addToPlaces(rowData.feature, false)
                                                            //             }
                                                            //         } catch (e) {

                                                            //         }
                                                            //     }
                                                            // }
                                                        ]}
                                                        localization={{
                                                            header: {
                                                                actions: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: this.props.translate('search'),
                                                                searchPlaceholder: this.props.translate('search')
                                                            },
                                                            pagination: {
                                                                labelDisplayedRows: '{from}-{to} ' + this.props.translate('from') + ' {count}',
                                                                labelRowsSelect: this.props.translate('labelRowsSelect'),
                                                                labelRowsPerPage: this.props.translate('labelRowsPerPage'),
                                                                firstAriaLabel: this.props.translate('firstAriaLabel'),
                                                                firstTooltip: this.props.translate('firstAriaLabel'),
                                                                previousAriaLabel: this.props.translate('previousAriaLabel'),
                                                                previousTooltip: this.props.translate('previousAriaLabel'),
                                                                nextAriaLabel: this.props.translate('nextAriaLabel'),
                                                                nextTooltip: this.props.translate('nextAriaLabel'),
                                                                lastAriaLabel: this.props.translate('lastAriaLabel'),
                                                                lastTooltip: this.props.translate('lastAriaLabel')
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <div />
                                            }
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            </div>
                            : ''
                    }
                    )
                }</ModalBody>
            </Modal>

            {
                this.state.loading ? <Spinner color='#000000' size={100} className='spinner' /> : ''
            }

            {/*<IntroModal show={this.state.showIntroModal}/>*/}

            <Modal isOpen={this.state.showIntroModal} toggle={this.toggleIntroModal} className='intro-modal' scrollable={true}>
                <ModalHeader toggle={this.toggleIntroModal}>
                    <Translate id='reconstruction' />
                </ModalHeader>
                <ModalBody style={{ textAlign: 'center' }}>
                    {
                        window.innerWidth > 820 ?
                            <>
                                <Row>
                                    <Col>
                                        <h5 className='modal-title'>Νέες Υποδομές | Πολιτισμός | Φύση & Πράσινο | Αθλητισμός | Ψυχαγωγία</h5>
                                        <img src={'img/intro.png'} alt="intro.png" style={{ width: '80%', marginBottom: '1em' }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='introP4' /></div>
                                    </Col>
                                    <Col>
                                        <img src={'photos/spm_drone_0030.jpg'} alt="spm_drone_0030.jpg" style={{ width: '100%' }} />
                                        <div style={{ fontSize: 'small' }}>Αεροφωτογραφία Στρατοπέδου Παύλου Μελά</div>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <h5 className='modal-title'>Νέες Υποδομές | Πολιτισμός | Φύση & Πράσινο | Αθλητισμός | Ψυχαγωγία</h5>
                                <img src={'img/intro.png'} alt="intro.png" style={{ width: '80%', marginBottom: '1em' }} />
                                <div style={{ textAlign: 'justify', marginTop: '0.5em', marginBottom: '0.5em' }}><Translate id='introP4' /></div>
                                <img src={'photos/spm_drone_0030.jpg'} alt="spm_drone_0030.jpg" style={{ width: '100%' }} />
                                <div style={{ fontSize: 'small' }}>Αεροφωτογραφία Στρατοπέδου Παύλου Μελά</div>
                            </>
                    }
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='introP5' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='introP6' /></div>
                    <ChangeLanguage />
                </ModalBody>
                <ModalFooter>
                    <img src={'img/image001.jpg'} alt="Conneccting Nature" style={{ width: '5em' }} />
                    <img src={'img/image003.jpg'} alt="EE" style={{ width: '5em' }} />
                    <img src={'img/logo.png'} alt="Municipality of Pavlos Melas" style={{ width: '5em' }} />
                    <div style={{ textAlign: 'right', fontSize: 'smaller' }}>Created by <a href="http://www.cartografia.gr" target="new">www.cartografia.gr</a></div>
                </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.showMoreMayorModal} toggle={this.toggleMoreMayorModal} className='intro-modal'
                scrollable={true}>
                <ModalHeader toggle={this.toggleMoreMayorModal}><Translate id='mayor' /></ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='mayorP1' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='mayorP2' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='mayorP3' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='mayorP4' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='mayorP5' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='mayorP6' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='mayorP7' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='mayorP8' /></div>
                    <div style={{ textAlign: 'center', marginTop: '2em' }}><Translate id='mayorP9' /></div>
                    <div style={{ textAlign: 'center', marginTop: '0.5em' }}><Translate id='mayorP10' /></div>
                </ModalBody>
                <ModalFooter>
                    <img src={'img/image001.jpg'} alt="Conneccting Nature" style={{ width: '5em' }} />
                    <img src={'img/image003.jpg'} alt="EE" style={{ width: '5em' }} />
                    <img src={'img/logo.png'} alt="Municipality of Pavlos Melas" style={{ width: '5em' }} />
                    <div style={{ textAlign: 'right', fontSize: 'smaller' }}>Created by <a href="http://www.cartografia.gr" target="new">www.cartografia.gr</a></div>
                </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.showHistoryModal} toggle={this.toggleHistoryModal} className='intro-modal'
                scrollable={true}>
                <ModalHeader toggle={this.toggleHistoryModal}><Translate id='history' /></ModalHeader>
                <ModalBody style={{ textAlign: 'center' }}>
                    {
                        window.innerWidth > 820 ?
                            <Row>
                                <Col>
                                    <div style={{ textAlign: 'justify', marginBottom: '1em' }}><Translate id='historyP1' /></div>
                                </Col>
                                <Col>
                                    <img src={'img/Picture4.png'} alt="Picture4.png" style={{ width: '100%' }} />
                                    <div style={{ fontSize: 'small' }}>Πηγή: Συλλογή Βασίλη Πανταζόπουλου, 1923</div>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    <div style={{ textAlign: 'justify', marginBottom: '1em' }}><Translate id='historyP1' /></div>
                                    <img src={'img/Picture4.png'} alt="Picture4.png" style={{ width: '100%' }} />
                                    <div style={{ fontSize: 'small' }}>Πηγή: Συλλογή Βασίλη Πανταζόπουλου, 1923</div>
                                </Col>
                            </Row>
                    }
                    <div style={{ textAlign: 'justify', marginTop: '1em', marginBottom: '1em' }}><Translate id='historyP2' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '1em', marginBottom: '1em' }}><Translate id='historyP3' /></div>
                    {
                        window.innerWidth > 820 ?
                            <Row>
                                <Col>
                                    <img src={'img/Picture5.png'} alt="Picture5.png" style={{ width: '100%' }} />
                                    <div style={{ fontSize: 'small' }}>Πηγή: Ministrere de la Culture, France, 1916</div>
                                </Col>
                                <Col>
                                    <div style={{ textAlign: 'justify', marginBottom: '1em' }}><Translate id='historyP4' /></div>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    <img src={'img/Picture5.png'} alt="Picture5.png" style={{ width: '100%' }} />
                                    <div style={{ fontSize: 'small' }}>Πηγή: Ministrere de la Culture, France, 1916</div>
                                    <div style={{ textAlign: 'justify', marginBottom: '1em' }}><Translate id='historyP4' /></div>
                                </Col>
                            </Row>

                    }
                    <div style={{ textAlign: 'justify', marginTop: '1em' }}><Translate id='historyP5' /></div>
                    {
                        window.innerWidth > 820 ?
                            <Row>
                                <Col>
                                    <div style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1.5em' }}>
                                        <img src={'img/Picture1.png'} alt="Picture1.png" style={{ width: '95.5%' }} />
                                        <div style={{ fontSize: 'small' }}>Χάρτης του 1909 - Συλλογή Παπαδημητρίου - Εθνική Χαρτοθήκη</div>
                                    </div>
                                    <div style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1.5em' }}>
                                        <img src={'img/Picture3.png'} alt="Picture1.png" style={{ width: '100%' }} />
                                        <div style={{ fontSize: 'small' }}>Χάρτης του 1944 - Συλλογή Παπαδημητρίου - Εθνική Χαρτοθήκη</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1.5em' }}>
                                        <img src={'img/Picture2.png'} alt="Picture1.png" style={{ width: '100%' }} />
                                        <div style={{ fontSize: 'small' }}>Χάρτης του 1927 - Συλλογή Παπαδημητρίου - Εθνική Χαρτοθήκη</div>
                                    </div>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    <div style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1.5em' }}>
                                        <img src={'img/Picture1.png'} alt="Picture1.png" style={{ width: '95.5%' }} />
                                        <div style={{ fontSize: 'small' }}>Χάρτης του 1909 - Συλλογή Παπαδημητρίου - Εθνική Χαρτοθήκη</div>
                                    </div>
                                    <div style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1.5em' }}>
                                        <img src={'img/Picture2.png'} alt="Picture1.png" style={{ width: '100%' }} />
                                        <div style={{ fontSize: 'small' }}>Χάρτης του 1927 - Συλλογή Παπαδημητρίου - Εθνική Χαρτοθήκη</div>
                                    </div>
                                    <div style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1.5em' }}>
                                        <img src={'img/Picture3.png'} alt="Picture1.png" style={{ width: '100%' }} />
                                        <div style={{ fontSize: 'small' }}>Χάρτης του 1944 - Συλλογή Παπαδημητρίου - Εθνική Χαρτοθήκη</div>
                                    </div>
                                </Col>
                            </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <img src={'img/image001.jpg'} alt="Conneccting Nature" style={{ width: '5em' }} />
                    <img src={'img/image003.jpg'} alt="EE" style={{ width: '5em' }} />
                    <img src={'img/logo.png'} alt="Municipality of Pavlos Melas" style={{ width: '5em' }} />
                    <div style={{ textAlign: 'right', fontSize: 'smaller' }}>Created by <a href="http://www.cartografia.gr" target="new">www.cartografia.gr</a></div>
                </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.showAboutTheProgramModal} toggle={this.toggleAboutTheProgramModal} className='intro-modal'
                scrollable={true}>
                <ModalHeader toggle={this.toggleAboutTheProgramModal}><Translate id='aboutTheProgram' /></ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='aboutTheProgramP1' /></div>
                    <div style={{ textAlign: 'justify', marginTop: '0.5em' }}><Translate id='aboutTheProgramP2' /></div>
                </ModalBody>
                <ModalFooter>
                    <img src={'img/image001.jpg'} alt="Conneccting Nature" style={{ width: '5em' }} />
                    <img src={'img/image003.jpg'} alt="EE" style={{ width: '5em' }} />
                    <img src={'img/logo.png'} alt="Municipality of Pavlos Melas" style={{ width: '5em' }} />
                    <div style={{ textAlign: 'right', fontSize: 'smaller' }}>Created by <a href="http://www.cartografia.gr" target="new">www.cartografia.gr</a></div>
                </ModalFooter>
            </Modal>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>);
        // }
        // return (<MobileLayout />)
    }
}

export default withLocalize(Greece);
