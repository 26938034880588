import React, { Component } from 'react';
import appstore from '../../css/icons/appstore.svg';
import playstore from '../../css/icons/playstore.png';

class MobileLayout extends Component {
  render() {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <a href="https://play.google.com/store/apps/details?id=gr.pavlosmelas.mppm"><img src={playstore} alt='play store' width={230} style={{ marginBottom: '1em' }} /></a><br />
        <a href="https://apps.apple.com/us/app/pavlos-melas-park/id1629716304"><img src={appstore} alt='app store' width={200} style={{ marginLeft: '1em' }} /></a>
      </div>
    );
  }
}

export default MobileLayout;