import { Tile as TileLayer } from 'ol/layer';
import XYZ from 'ol/source/XYZ';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';

import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import {get as getProjection} from 'ol/proj';
import {getTopLeft, getWidth} from 'ol/extent';

const projection = getProjection('EPSG:3857');
const projectionExtent = projection.getExtent();
const size = getWidth(projectionExtent) / 256;
const resolutions = new Array(19);
const matrixIds = new Array(19);
for (let z = 0; z < 25; ++z) {
  // generate resolutions and matrixIds arrays for this WMTS
  resolutions[z] = size / Math.pow(2, z);
  matrixIds[z] = z;
}

console.log(matrixIds)

const layers2d = [

    //Basemaps
    new TileLayer({
        id: "ESRI_World_Imagery",
        title: "stamenWatercolor",
        baseLayer: true,
        visible: true,
        source: new XYZ({
            crossOrigin: "Anonymous",
            attributions:
                'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer">ArcGIS</a>',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        }),
    }),
    new TileLayer({
        id: "ESRI_World_Street_Map",
        title: "stamenWatercolor",
        baseLayer: true,
        visible: false,
        source: new XYZ({
            crossOrigin: "Anonymous",
            attributions:
                'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer">ArcGIS</a>',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
        }),
    }),


    //Layers       
    new ImageLayer({
        id: "8_Green_Areas",
        title: "8_Green_Areas",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2550502.159, 4960395.091, 2557840.114, 4964675.564],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '8_Green_Areas',
                'TILED': true,
                'STYLES': '8_Green_Areas'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "7_Churchs",
        title: "7_Churchs",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2550502.159, 4960395.091, 2557840.114, 4964675.564],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '7_Churchs',
                'TILED': true,
                'STYLES': '7_Churchs'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "6_Entertainment",
        title: "6_Entertainment",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2550502.159, 4960395.091, 2557840.114, 4964675.564],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '6_Entertainment',
                'TILED': true,
                'STYLES': '6_Entertainment'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "5_Sports",
        title: "5_Sports",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2550502.159, 4960395.091, 2557840.114, 4964675.564],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '5_Sports',
                'TILED': true,
                'STYLES': '5_Sports'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "4_Culture",
        title: "4_Culture",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2550502.159, 4960395.091, 2557840.114, 4964675.564],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '4_Culture',
                'TILED': true,
                'STYLES': '4_Culture'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "3_Existing_Infrastructure",
        title: "3_Existing_Infrastructure",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2550502.159, 4960395.091, 2557840.114, 4964675.564],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '3_Existing_Infrastructure',
                'TILED': true,
                'STYLES': '3_Existing_Infrastructure'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "2_New_Infrastructures",
        title: "2_New_Infrastructures",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2550502.159, 4960395.091, 2557840.114, 4964675.564],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '2_New_Infrastructures',
                'TILED': true,
                'STYLES': '2_New_Infrastructures'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "1_Entrances",
        title: "1_Entrances",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2550502.159, 4960395.091, 2557840.114, 4964675.564],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '1_Entrances',
                'TILED': true,
                'STYLES': '1_Entrances'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),

];

const layers3d = [

    //Basemaps
    new TileLayer({
        id: "ESRI_World_Street_Map",
        title: "stamenWatercolor",
        baseLayer: true,
        visible: true,
        info: false,
        preview: 'simos',
        // source: new TileWMS({
        //     url: process.env.REACT_APP_GEOSERVER_URL,
        //     params: {
        //         'LAYERS': 'map',
        //         'TILED': true,
        //     },
        //     crossOrigin: "Anonymous",
        //     serverType: 'geoserver',
        //     transition: 0
        // })
        source: new WMTS({
            url: process.env.REACT_APP_GEOSERVER_URL_WMTS,
            layer: process.env.REACT_APP_GEOSERVER_WMTS_LAYER,
            matrixSet: 'WebMercatorQuad',
            format: 'image/png',
            projection: projection,
            tileGrid: new WMTSTileGrid({
              origin: getTopLeft(projectionExtent),
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            wrapX: true,
          }),
    }),


    //Layers        
    new ImageLayer({
        id: "8_Green_Areas",
        title: "8_Green_Areas",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2171775,-4024,2180409,385],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '8_Green_Areas_transform',
                'TILED': true,
                'STYLES': '8_Green_Areas'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "7_Churchs",
        title: "7_Churchs",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2171775,-4024,2180409,385],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '7_Churchs_transform',
                'TILED': true,
                'STYLES': '7_Churchs'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "6_Entertainment",
        title: "6_Entertainment",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2171775,-4024,2180409,385],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '6_Entertainment_transform',
                'TILED': true,
                'STYLES': '6_Entertainment'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "5_Sports",
        title: "5_Sports",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2171775,-4024,2180409,385],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '5_Sports_transform',
                'TILED': true,
                'STYLES': '5_Sports'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "4_Culture",
        title: "4_Culture",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2171775,-4024,2180409,385],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '4_Culture_transform',
                'TILED': true,
                'STYLES': '4_Culture'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "3_Existing_Infrastructure",
        title: "3_Existing_Infrastructure",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2171775,-4024,2180409,385],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '3_Existing_Infrastructure_transform',
                'TILED': true,
                'STYLES': '3_Existing_Infrastructure'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "2_New_Infrastructures",
        title: "2_New_Infrastructures",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2171775,-4024,2180409,385],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '2_New_Infrastructures_transform',
                'TILED': true,
                'STYLES': '2_New_Infrastructures'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "1_Entrances",
        title: "1_Entrances",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [2171775,-4024,2180409,385],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER_URL,
            params: {
                'LAYERS': '1_Entrances_transform',
                'TILED': true,
                'STYLES': '1_Entrances'
            },
            crossOrigin: "Anonymous",
            serverType: 'geoserver',
            transition: 0
        })
    }),

];

export const map2d = {
    xy: [2553545, 4962396],
    zoom: 17,
    extent: [2550502.159, 4960395.091, 2557840.114, 4964675.564],
    extent_limits: [2545782.172, 4954776.969, 2567719.600, 4972701.452],
    layers: layers2d
}

export const map3d = {
    xy: [2176092,-1819.5],
    zoom: 10,
    extent: [2171775,-4024,2180409,385],
    extent_limits: [2171775,-4024,2180409,385],
    layers: layers3d
}