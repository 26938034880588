import React from 'react';
import ReactDOM from 'react-dom';
import './css/bootstrap.min.css';
import './css/index.css';
import {LocalizeProvider} from "react-localize-redux";
import Routes from './routes';


ReactDOM.render(<LocalizeProvider>
        <Routes/>
</LocalizeProvider>, document.getElementById('root'));