import React, {Component} from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import {BrowserRouter as Router, Route} from "react-router-dom";
import { withLocalize} from "react-localize-redux";
import globalTranslations from "./translations/global.json";
import './css/bootstrap.min.css';
import './css/index.css';
import Greece from './layouts/greece/greece';
import TermsOfUse from './layouts/termsOfUse/termsOfUse';

class Routes extends Component {

  constructor(props) {
    super(props);
    this.props.initialize({
      languages: [
        {
          name: "English",
          code: "en"
        },  {
          name: "Greek",
          code: "gr"
        }
      ],
      defaultLanguage: 'gr',
      translation: globalTranslations,
      options: {
        defaultLanguage: "gr",
        renderToStaticMarkup
      }
    });
  }

  render() {
    return (
        <Router>
          <div>
            <Route exact path="/" component={Greece}/>
            <Route exact path="/termsOfUse" component={TermsOfUse}/>
          </div>
        </Router>
    );
  }
}

export default withLocalize(Routes);
